export const ValueItem = {
  title: 'Value Item',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'image'],
    },
  ],
  properties: {
    title: {
      title: 'Value',
    },
    description: {
      title: 'Description',
    },
    image: {
      title: 'Image',
      widget: 'object_browser',
      mode: 'link',
      multiple: false,
      selectedItemAttrs: [],
    },
  },
  required: ['title', 'description', 'image'],
};

export const ValuesSchema = (props) => {
  return {
    title: 'Values',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['valueItem'],
      },
    ],
    properties: {
      valueItem: {
        title: 'Value Item',
        widget: 'object_list',
        schema: ValueItem,
      },
    },
    required: [],
  };
};

export default ValuesSchema;
