import { Icon } from '@affinitic/volto-icon-selector-widget/components';
import cx from 'classnames';

const DisplayIcon = (props) => {
  const { icon = null, icon_pos = null, icon_pos_value } = props;
  if (icon === null) {
    return <></>;
  }
  if (icon_pos === icon_pos_value) {
    return <Icon name={icon} />;
  } else {
    return <></>;
  }
};

const Body = (props) => {
  const { data } = props;
  return (
    <>
      <div
        className={cx('highlight', 'block', data.align)}
        style={data.color_highlight}
      >
        <div className="block-header">
          <>
            {}
            {data.title && (
              <h3>
                <DisplayIcon {...data} icon_pos_value="front" /> {data.title}{' '}
                <DisplayIcon {...data} icon_pos_value="back" />
              </h3>
            )}
          </>
        </div>
        <div className="block-content">
          {data.contents &&
            data.contents.map((content) => (
              <div key={content['@id']} className="item">
                {content.link ? (
                  <a href={content.link} className="content">
                    <DisplayIcon {...content} icon_pos_value="front" />
                    {content.title}
                    <DisplayIcon {...content} icon_pos_value="back" />
                  </a>
                ) : (
                  <div className="content">
                    <DisplayIcon {...content} icon_pos_value="front" />
                    {content.title}
                    <DisplayIcon {...content} icon_pos_value="back" />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Body;
