import React, { Component } from 'react';
import Body from './Body';

class View extends Component {
  render() {
    return <Body {...this.props} />;
  }
}

export default View;
