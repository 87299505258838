import React, { Component } from 'react';
import { SidebarPortal } from '@plone/volto/components';
import TeamsData from './TeamsData';
import TeamsBody from './TeamsBody';

class Edit extends Component {
  render() {
    return (
      <>
        Teams
        <TeamsBody data={this.props.data} id={this.props.block} />
        <SidebarPortal selected={this.props.selected}>
          <TeamsData
            key={this.props.block}
            {...this.props}
            data={this.props.data}
            block={this.props.block}
            onChangeBlock={this.props.onChangeBlock}
          />
        </SidebarPortal>
      </>
    );
  }
}

export default Edit;
