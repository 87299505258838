import React, { Component } from 'react';
import ServicesListBody from './ServicesListBody';

class View extends Component {
  render() {
    return <ServicesListBody {...this.props} />;
  }
}

export default View;
