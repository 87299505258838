import React from 'react';
import cx from 'classnames';

const Body = (props) => {
  const { data } = props;
  return (
    <div
      className={cx('spacer')}
      style={{ '--spacer-size': `${data.size}px` }}
    />
  );
};

export default Body;
