import react, { useState } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

const solutionItem = {
  title: 'Solution Item',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'anchor'],
    },
  ],
  properties: {
    title: {
      title: 'Title',
    },
    description: {
      title: 'Description',
      widget: 'textarea',
    },
    anchor: {
      title: 'Id',
      description: 'element id for anchor target',
      default: '',
    },
  },
  required: ['title', 'description'],
};

export const Schema = (props) => {
  return {
    title: 'Anchor',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'link', 'linkTitle', 'solutionItems'],
      },
    ],
    properties: {
      title: {
        title: 'title',
      },
      link: {
        title: 'Link',
        widget: 'internal_url',
      },
      linkTitle: {
        title: 'Link Title',
      },
      solutionItems: {
        title: 'Solution Items',
        widget: 'object_list',
        schema: solutionItem,
      },
    },
    required: [],
  };
};
