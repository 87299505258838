import react, { useState } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

const eventSchema = (props) => {
  return {
    title: 'Events',
    addMessage: 'Add Event',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['eventTitle', 'description', 'date', 'day', 'height', 'side'],
      },
    ],
    properties: {
      eventTitle: {
        title: 'Title',
      },
      description: {
        title: 'Description',
        widget: 'richtext',
      },
      date: {
        title: 'Date',
        widget: 'date',
      },
      day: {
        title: 'Display day ?',
        type: 'boolean',
      },
      height: {
        title: 'Height',
        type: 'number',
      },
      side: {
        title: 'Side',
        choices: [
          ['left', 'Left'],
          ['right', 'Right'],
        ],
        default: 'left',
        noValueOption: false,
      },
    },
    required: [],
  };
};

export const Schema = (props) => {
  return {
    title: 'Timeline',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['startDate', 'events'],
      },
    ],
    properties: {
      startDate: {
        title: 'Start Date',
        widget: 'date',
      },
      events: {
        title: 'Events',
        widget: 'object_list',
        schema: eventSchema(props),
      },
    },
    required: [],
  };
};
