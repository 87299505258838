import react, { useState } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

export const Schema = (props) => {
  return {
    title: 'Anchor',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'link', 'align', 'valign', 'size', 'style'],
      },
    ],
    properties: {
      title: {
        title: 'title',
      },
      link: {
        title: 'Link',
        widget: 'internal_url',
      },
      align: {
        title: 'Align',
        widget: 'align',
        default: 'left',
      },
      valign: {
        title: 'Vertical Align',
        choices: [
          ['top', 'Top'],
          ['va-center', 'Center'],
          ['bottom', 'Bottom'],
          ['stretch', 'Fill'],
        ],
        default: 'va-center',
      },
      size: {
        title: 'Size',
        choices: [
          ['mini', 'Mini'],
          ['tiny', 'Tiny'],
          ['small', 'Small'],
          ['medium', 'Medium'],
          ['large', 'Large'],
          ['big', 'Big'],
          ['huge', 'Huge'],
          ['massive', 'Massive'],
        ],
      },
      style: {
        title: 'Style',
        choices: [['animate', 'Animate']],
      },
    },
    required: [],
  };
};
