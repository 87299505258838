export const ServiceItem = {
  title: 'Service Item',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'url', 'anchor'],
    },
  ],
  properties: {
    title: {
      title: 'Title',
    },
    url: {
      title: 'URL',
      widget: 'object_browser',
      mode: 'link',
      multiple: false,
      selectedItemAttrs: [],
    },
    anchor: {
      title: 'Anchor',
      default: '',
    },
  },
  required: ['title', 'url', 'anchor'],
};

export const ServicesListSchema = (props) => {
  return {
    title: 'Service List',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['serviceItem'],
      },
    ],
    properties: {
      serviceItem: {
        title: 'Service Item',
        widget: 'object_list',
        schema: ServiceItem,
      },
    },
    required: [],
  };
};

export default ServicesListSchema;
