import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import './wrapperStyle.less';

const LockWrapper = (props) => {
  return (
    <Container id="affintic-website-lock-login" className="wraper">
      <Grid columns={3}>
        <Grid.Column width="5"></Grid.Column>
        <Grid.Column width="1"></Grid.Column>
        <Grid.Column width="5">{props.children}</Grid.Column>
      </Grid>
    </Container>
  );
};

export default LockWrapper;
