import { useState, useRef, useEffect } from 'react';
import { Icon } from '@affinitic/volto-icon-selector-widget/components';
import cx from 'classnames';
import { Accordion } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';

const Panel = (props) => {
  const { className, children, active } = props;
  const [panelHeight, setPanelHeight] = useState(null);
  const panelRef = useRef(null);

  useEffect(() => {
    if (active && panelHeight) {
      panelRef.current.style.height = panelHeight;
    } else {
      panelRef.current.style.height = '60px';
    }
  }, [active, panelHeight]);

  const handleDivLoad = (event) => {
    setPanelHeight(event.target.clientHeight);
  };
  return (
    <div
      className={cx('panel', { active: active, className: className })}
      ref={panelRef}
      // onClick={onClick}
      onLoad={handleDivLoad}
    >
      {children}
    </div>
  );
};

const Body = (props) => {
  const { data } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);

  const handleClick = (index) => {
    setActiveIndex(index);
    expandPanel(index);
  };

  const handleFocus = (index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
      expandPanel(index);
    }
  };

  const handleBlur = (index) => {
    if (activeIndex === index) {
      retractPanel(index);
    }
  };

  const retractPanel = (index) => {
    if (contentRefs.current[index]) {
      contentRefs.current[index].style.height = '0px';
    }
    setActiveIndex(null);
  };

  const expandPanel = (index) => {
    if (contentRefs.current[index]) {
      const scrollHeight = contentRefs.current[index].scrollHeight;
      console.log('🚀 ~ expandPanel ~ scrollHeight:', scrollHeight);
      contentRefs.current[index].style.height = `${scrollHeight}px`;
    }
  };

  return (
    <>
      <div
        className={cx('affinitic-accordion', 'block', data.align)}
        style={data.color_highlight}
      >
        <Accordion styled>
          {data.contents &&
            data.contents.map((item, index) => (
              <div key={item['@id']} className="panel">
                <Accordion.Title
                  active={activeIndex === index}
                  index={index}
                  onClick={() => handleClick(index)}
                  onFocus={() => handleFocus(index)}
                  onblur={() => handleBlur(index)}
                >
                  <div className="content">{item.title}</div>
                  <div className="icon">
                    <Icon name="plus" />
                  </div>
                </Accordion.Title>
                <Accordion.Content
                  ref={(el) => (contentRefs.current[index] = el)}
                  style={{ display: activeIndex === index ? 'block' : 'none' }}
                >
                  {ReactHtmlParser(item.description.data)}
                </Accordion.Content>
              </div>
            ))}
        </Accordion>
      </div>
    </>
  );
};

export default Body;
