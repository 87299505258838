import react, { useState } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

const contentsSchema = {
  title: 'Contents',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description'],
    },
  ],
  properties: {
    title: {
      title: 'Title',
    },
    description: {
      title: 'Description',
      widget: 'richtext',
    },
  },
  required: ['title', 'description'],
};

export const Schema = (props) => {
  return {
    title: 'Highlight',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['contents'],
      },
    ],
    properties: {
      contents: {
        title: 'Contents',
        widget: 'object_list',
        schema: contentsSchema,
      },
    },
    required: [],
  };
};
