import React from 'react';
import NavItem from '@plone/volto/components/theme/Navigation/NavItem';
import { Dropdown, Icon } from 'semantic-ui-react';

const NavItems = ({ items, lang }) => {
  return (
    <>
      {items.map((item) =>
        item && item.items && item.items.length > 0 ? (
          <div className="item has_tree">
            <a href={item.url} className="text">
              {item.title}
              <Icon name="caret right" />
            </a>
            <div className="sub_tree">
              {item.items.map((dropdownitem) =>
                dropdownitem &&
                dropdownitem.items &&
                dropdownitem.items.length > 0 ? (
                  <>
                    <div className="item has_subtree">
                      <a href={dropdownitem.url} className="text">
                        {dropdownitem.title}
                        <Icon name="caret right" />
                      </a>
                      <div className="sub_sub_tree">
                        {dropdownitem.items.map((subDropdownItem) => (
                          <NavItem
                            item={subDropdownItem}
                            lang={lang}
                            key={subDropdownItem.url}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <NavItem
                      item={dropdownitem}
                      lang={lang}
                      key={dropdownitem.url}
                    />
                  </>
                ),
              )}
            </div>
          </div>
        ) : (
          <NavItem item={item} lang={lang} key={item.url} />
        ),
      )}
    </>
  );
};

export default NavItems;
