import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import Data from './Data';
import Body from './Body';
import { withBlockExtensions } from '@plone/volto/helpers';
import { getBaseUrl } from '@plone/volto/helpers';

const Edit = (props) => {
  const { data, onChangeBlock, block, selected, pathname } = props;

  return (
    <>
      <Body {...props} path={getBaseUrl(pathname)} isEditMode />
      <SidebarPortal selected={selected}>
        <Data
          key={block}
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

export default withBlockExtensions(Edit);
