import react, { useState } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

export const Schema = (props) => {
  return {
    title: 'Spacer',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['size'],
      },
    ],
    properties: {
      size: {
        title: 'Space Size (px)',
        type: 'number',
        default: 15,
      },
    },
    required: [],
  };
};
