import React, { Component } from 'react';
import ValuesBody from './ValuesBody';

class View extends Component {
  render() {
    return <ValuesBody {...this.props} />;
  }
}

export default View;
