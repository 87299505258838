import React from 'react';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';
import { toPublicURL } from '@plone/volto/helpers';
import { HashLink } from 'react-router-hash-link';
import { removeSpaceFromAnchor } from '~/helpers';

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -200;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const Body = (props) => {
  const { data } = props;
  return (
    <div className="solutions block full-width">
      <div className="block-header">
        {data.title && <h2>{data.title}</h2>}
        {data.link && data.linkTitle && (
          <a className="link" href={data.link}>
            {data.linkTitle}
          </a>
        )}
      </div>
      <div className="block-content">
        {data.solutionItems &&
          data.solutionItems.map((solution, index) => (
            <HashLink
              className={cx('solution-wrapper')}
              data-gradient={index + 1}
              key={solution['@id']}
              to={data.link + '#' + removeSpaceFromAnchor(solution.anchor)}
              smooth
              scroll={(el) => scrollWithOffset(el)}
            >
              <div className="solution-title">{solution.title}</div>
              <div className="solution-description">
                {solution.description}
              </div>
            </HashLink>
          ))}
      </div>
    </div>
  );
};

export default Body;
