import React from 'react';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';
import { toPublicURL } from '@plone/volto/helpers';

const Body = (props) => {
  const { data } = props;
  return (
    <div className={cx('anchor-block', data.align, data.valign, data.style)}>
      <Button as="a" size={data.size} href={toPublicURL(data.link)}>
        <div className="content">
          <div className="title">{data.title}</div>
        </div>
      </Button>
    </div>
  );
};

export default Body;
