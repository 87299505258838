export const Schema = (props) => {
  return {
    title: 'Service Search Bar',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [],
      },
    ],
    properties: {},
    required: [],
  };
};
