import React from 'react';
import FigureStatistics from './FigureStatistics';

const View = (props) => {
  return (
    <div>
      <FigureStatistics data={props.data} />
    </div>
  );
};

export default View;
