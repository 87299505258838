/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import React from 'react';

import {
  MapBannerEditBlock,
  MapBannerViewBlock,
  TeamsViewBlock,
  TeamsEditBlock,
  FiguresViewBlock,
  FiguresEditBlock,
  ServicesListViewBlock,
  ServicesListEditBlock,
  ProjectsListingTemplate,
  HomepageProjectListingTemplate,
  ClientCarouselTemplate,
  ProjectView,
  ClientView,
  ProductView,
  TimelineEditBlock,
  TimelineViewBlock,
  TimelineSchema,
  AnchorEditBlock,
  AnchorViewBlock,
  AnchorSchema,
  SpacerEditBlock,
  SpacerViewBlock,
  SpacerSchema,
  LockWrapper,
  ValuesViewBlock,
  ValuesEditBlock,
  ServiceFullViewBlock,
  ServiceFullEditBlock,
  SolutionViewBlock,
  SolutionEditBlock,
  SolutionsSchema,
  HighlightViewBlock,
  HighlightEditBlock,
  HighlightSchema,
  ServiceSearchBarViewBlock,
  ServiceSearchBarEditBlock,
  ServiceSearchBarSchema,
  AccordionViewBlock,
  AccordionEditBlock,
  AccordionSchema,
} from './components';

import { BlockButton } from '@plone/volto-slate/editor/ui';

import { ButtonCTA } from './styles';

import blocks from './styles/ToHTML';

import { Separator } from 'draft-js-inline-toolbar-plugin';
import sliderSVG from '@plone/volto/icons/slider.svg';
import imageSVG from '@plone/volto/icons/image.svg';
import groupSVG from '@plone/volto/icons/group.svg';
import alarmSVG from '@plone/volto/icons/alarm.svg';
import linkSVG from '@plone/volto/icons/link.svg';
import verticalSVG from '@plone/volto/icons/vertical.svg';
import ctaBtn from './icons/cta-btn.svg';

// import {installDemoStyles} from '@eeacms/volto-block-style';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly

  // config = installDemoStyles(config);
  // config.settings.bbb_listingBlockFetchesFullobjects = true;

  // config.settings.richTextEditorInlineToolbarButtons = [
  //   ...config.settings.richTextEditorInlineToolbarButtons,
  //   Separator,
  //   ButtonCTA,
  // ];
  config.settings.navDepth = 3;

  config.experimental.addBlockButton.enabled = true;

  config.settings['lock-login'] = {
    ...config.settings['lock-login'],
    wrapper: LockWrapper,
  };

  config.blocks.blocksConfig.__grid.gridAllowedBlocks = [
    ...config.blocks.blocksConfig.__grid.gridAllowedBlocks,
    'figures',
    'affinitic_anchor',
    'ServiceFull',
  ];

  config.settings.slate.buttons['ButtonCTA'] = (props) => (
    <BlockButton
      title="button-CTA"
      format="buttonCTA"
      icon={ctaBtn}
      {...props}
    />
  );

  config.settings.slate.elements = {
    ...config.settings.slate.elements,
    buttonCTA: ({ attributes, children }) => (
      <div className="buttonCTA" {...attributes}>
        {children}
      </div>
    ),
  };

  config.settings.slate.toolbarButtons = [
    ...config.settings.slate.toolbarButtons,
    'separator',
    'ButtonCTA',
  ];

  // config.settings.ToHTMLRenderers.blocks = {
  //   ...config.settings.ToHTMLRenderers.blocks,
  //   ...blocks,
  // };

  config.views.contentTypesViews.Project = ProjectView;
  config.views.contentTypesViews.Client = ClientView;
  config.views.contentTypesViews.Product = ProductView;

  config.blocks.blocksConfig['ServicesList'] = {
    id: 'ServicesList',
    title: 'ServicesList',
    icon: imageSVG,
    group: 'common',
    view: ServicesListViewBlock,
    edit: ServicesListEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['MapBanner'] = {
    id: 'MapBanner',
    title: 'MapBanner',
    icon: imageSVG,
    group: 'common',
    view: MapBannerViewBlock,
    edit: MapBannerEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['Teams'] = {
    id: 'Teams',
    title: 'Teams',
    icon: groupSVG,
    group: 'common',
    view: TeamsViewBlock,
    edit: TeamsEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['figures'] = {
    id: 'figures',
    title: 'Figures',
    icon: sliderSVG,
    group: 'common',
    view: FiguresViewBlock,
    edit: FiguresEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['Values'] = {
    id: 'Values',
    title: 'Values',
    icon: groupSVG,
    group: 'common',
    view: ValuesViewBlock,
    edit: ValuesEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['ServiceFull'] = {
    id: 'ServiceFull',
    title: 'Service Hero',
    icon: groupSVG,
    group: 'common',
    view: ServiceFullViewBlock,
    edit: ServiceFullEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['affinitic_timeline'] = {
    id: 'affinitic_timeline',
    title: 'Timeline',
    icon: alarmSVG,
    group: 'common',
    view: TimelineViewBlock,
    edit: TimelineEditBlock,
    blockSchema: TimelineSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['affinitic_anchor'] = {
    id: 'affinitic_anchor',
    title: 'Button',
    icon: linkSVG,
    group: 'common',
    view: AnchorViewBlock,
    edit: AnchorEditBlock,
    blockSchema: AnchorSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['affinitic_spacer'] = {
    id: 'affinitic_spacer',
    title: 'Spacer',
    icon: verticalSVG,
    group: 'common',
    view: SpacerViewBlock,
    edit: SpacerEditBlock,
    blockSchema: SpacerSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['affinitic_solutions'] = {
    id: 'affinitic_solutions',
    title: 'Solutions',
    icon: sliderSVG,
    group: 'common',
    view: SolutionViewBlock,
    edit: SolutionEditBlock,
    blockSchema: SolutionsSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['affinitic_highlight'] = {
    id: 'affinitic_highlight',
    title: 'Highlight',
    icon: sliderSVG,
    group: 'common',
    view: HighlightViewBlock,
    edit: HighlightEditBlock,
    blockSchema: HighlightSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['affinitic_service_search_bar'] = {
    id: 'affinitic_service_search_bar',
    title: 'Service Search Bar',
    icon: sliderSVG,
    group: 'common',
    view: ServiceSearchBarViewBlock,
    edit: ServiceSearchBarEditBlock,
    blockSchema: ServiceSearchBarSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['affinitic_accordion'] = {
    id: 'affinitic_accordion',
    title: 'Accordion',
    icon: sliderSVG,
    group: 'common',
    view: AccordionViewBlock,
    edit: AccordionEditBlock,
    blockSchema: AccordionSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.listing['variations'] = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'projectslist',
      isDefault: true,
      title: 'Projects list',
      template: ProjectsListingTemplate,
    },
    {
      id: 'client_carousel',
      isDefault: true,
      title: 'Client Carousel',
      template: ClientCarouselTemplate,
    },
    {
      id: 'homapageProjectsList',
      isDefault: true,
      title: 'Homapage Projects List',
      template: HomepageProjectListingTemplate,
    },
  ];

  return config;
}
