import React from 'react';
import { Card, Container, Image, Popup } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL, getParentUrl } from '@plone/volto/helpers';

// function that take getURL remove id and return url and client_logo
const getClientLogo = (item) => {
  const clientLogo = item.client_logo;
  const url = item.getURL;
  const urlClient = url?.substring(0, url?.lastIndexOf('/'));
  const clientLogoUrl = `${urlClient}/view/++widget++form.widgets.ILeadImageBehavior.image/@@download/${clientLogo}`;
  return clientLogoUrl;
};

const HomepageProjectListingTemplate = (props) => {
  const { items, isEditMode } = props;
  return (
    <div className="homepage-project block">
      <div className="left">
        <h2>Nos réalisations</h2>
      </div>
      <div className="right">
        {items.map((project) => (
          <div className="project" key={project.UID}>
            <div className="header">
              <h3 className="title">{project.Title}</h3>
              <Image
                src={`${flattenToAppURL(
                  getParentUrl(project['@id']),
                )}/@@images/image/mini`}
                size="mini"
                alt={project.client_name}
                title={project.client_name}
              />
            </div>
            <div className="description">{project.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomepageProjectListingTemplate;
