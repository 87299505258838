import react, { useState } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

const contentsSchema = {
  title: 'Contents',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'icon', 'icon_pos', 'link'],
    },
  ],
  properties: {
    title: {
      title: 'Title',
    },
    icon: {
      title: 'icon',
      widget: 'icon_selector',
    },
    link: {
      title: 'Link',
      widget: 'internal_url',
    },
    icon_pos: {
      title: 'Icon Position',
      choices: [
        ['front', 'Front'],
        ['back', 'Back'],
      ],
    },
  },
  required: ['title'],
};

export const Schema = (props) => {
  return {
    title: 'Highlight',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'icon',
          'icon_pos',
          'contents',
          'align',
          'color_highlight',
        ],
      },
    ],
    properties: {
      title: {
        title: 'title',
      },
      icon: {
        title: 'icon',
        widget: 'icon_selector',
      },
      contents: {
        title: 'Contents',
        widget: 'object_list',
        schema: contentsSchema,
      },
      icon_pos: {
        title: 'Icon Position',
        choices: [
          ['front', 'Front'],
          ['back', 'Back'],
        ],
      },
      align: {
        title: 'Align',
        widget: 'align',
        default: 'left',
      },
      color_highlight: {
        title: 'Color Theme',
        widget: 'color_picker',
        colors: [
          {
            name: 'default',
            label: 'Default',
            style: {
              '--style-color': '#343434',
            },
          },
          {
            name: 'affinitic_blue',
            label: 'Affinitic Blue',
            style: {
              '--style-color': '#2D5467',
            },
          },
          {
            name: 'orange',
            label: 'Orange',
            style: {
              '--style-color': 'orange',
            },
          },
          {
            name: 'select_color',
            label: 'Select Color',
            style: {
              '--style-color': '#b22c2c',
            },
          },
        ],
      },
    },
    required: [],
  };
};
