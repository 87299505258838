import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import { formatDate } from '@plone/volto/helpers/Utils/Date';
import logo from '@root/assets/images/Logo.svg';
import { Image } from 'semantic-ui-react';
import { FormattedDate } from '@plone/volto/components';

import 'react-vertical-timeline-component/style.min.css';

const Body = (props) => {
  console.log('🚀 ~ file: Body.jsx:11 ~ Body ~ props:', props);
  const { data } = props;
  const { events } = data;
  return (
    <div className="timeline-block">
      <div className="wrapper-logo">
        <div className="logo">
          <Image src={logo} size="tiny" />
        </div>
        {data.startDate && (
          <div className="start-date">
            <FormattedDate
              date={data.startDate}
              locale="fr"
              format={{
                year: 'numeric',
              }}
            />
          </div>
        )}
      </div>
      {events && (
        <VerticalTimeline lineColor="#2D5467">
          {events.map((event) => {
            const format = event.day
              ? { day: '2-digit', month: 'long', year: 'numeric' }
              : {
                  month: 'long',
                  year: 'numeric',
                };
            return (
              <VerticalTimelineElement
                date={
                  event.date
                    ? formatDate({
                        date: event.date,
                        locale: 'fr',
                        format: format,
                      })
                    : ''
                }
                position={event.side ? event.side : 'left'}
                style={{
                  '--vertical-offset': event.height
                    ? `${event.height}px`
                    : '0px',
                }}
              >
                {event.eventTitle && <h3>{event.eventTitle}</h3>}
                {event.description && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: event.description.data,
                    }}
                  />
                )}
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      )}
    </div>
  );
};

export default Body;
