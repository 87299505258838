import React from 'react';
import Body from './Body';
import { withBlockExtensions } from '@plone/volto/helpers';

const View = (props) => {
  const { data, path, pathname, className } = props;
  return <Body {...props} path={path ?? pathname} />;
};

export default withBlockExtensions(View);
