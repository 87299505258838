/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import {
  Container,
  List,
  Segment,
  Grid,
  Icon,
  Image,
} from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink, Logo } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import LogoImage from '@plone/volto/components/theme/Logo/Logo.svg';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { settings } = config;
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      textAlign="center"
      id="footer"
    >
      <Container>
        <Segment basic inverted className="discreet">
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column width="5">
                <Container textAlign="right">
                  <List inverted link>
                    <List.Item>
                      <List.Content>
                        <a href="mailto:info@affinitic.be">
                          info@affinitic.be
                        </a>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <a href="tel:+32483460840">
                          Administration | Technique : +32 (0) 483 46 08 40
                        </a>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <a href="tel:+32483684799">
                          Gestion de projet : +32 (0) 483 68 47 99
                        </a>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <div className="spacer" />
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <List horizontal inverted link>
                          <List.Item>
                            <List.Content>
                              <a href="https://www.facebook.com/Affinitic/">
                                <Icon name="facebook f" size="large" />
                              </a>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Content>
                              <a href="https://twitter.com/affinitic">
                                <Icon name="twitter" size="large" />
                              </a>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Content>
                              <a href="https://www.linkedin.com/company/affinitic-s-p-r-l-/">
                                <Icon name="linkedin" size="large" />
                              </a>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Content>
                              <a href="https://github.com/affinitic">
                                <Icon name="github" size="large" />
                              </a>
                            </List.Content>
                          </List.Item>
                        </List>
                      </List.Content>
                    </List.Item>
                  </List>
                </Container>
              </Grid.Column>
              <Grid.Column className="logo" width="1">
                <Image
                  src={LogoImage}
                  alt="Affinitic Logo"
                  title="Affinitic Logo"
                  size="tiny"
                />
              </Grid.Column>
              <Grid.Column width="5">
                <Container textAlign="left">
                  <List inverted link>
                    <List.Item>
                      <List.Content>Affinitic Srl</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <a
                          href="https://goo.gl/maps/W2izTwT5ZKD8Eu3J9"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Rue de la Maîtrise 5 D
                        </a>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <a
                          href="https://goo.gl/maps/W2izTwT5ZKD8Eu3J9"
                          target="_blank"
                          rel="noreferrer"
                        >
                          B-1400 Nivelles Belgique
                        </a>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        Numéro d’entreprise : 0882 564 990
                      </List.Content>
                    </List.Item>
                  </List>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
