import cx from 'classnames';
import { useEffect, useReducer, useRef, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getVocabulary } from '@plone/volto/actions';
import { GridColumn, Search, Grid, Header, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';

const initialState = {
  loading: false,
  results: [],
  value: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection };

    default:
      throw new Error();
  }
}

const ResultRender = (props) => {
  return <a href={props.href}>{props.title}</a>;
};

const Body = (props) => {
  const { content } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, results, value } = state;
  const vocabulary = 'affinitic.service_pages';
  const options = useSelector((state) => state?.vocabularies?.[vocabulary]?.items)?.map(
    (item) => {
      return { title: item.label, href: `${content['@id']}${item.value}` };
    },
  );

  const rDispatch = useDispatch();
  useEffect(() => {
    rDispatch(getVocabulary({ vocabNameOrURL: vocabulary }));
  }, [rDispatch]);

  const timeoutRef = useRef();
  const handleSearchChange = useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);
      dispatch({ type: 'START_SEARCH', query: data.value });

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch({ type: 'CLEAN_QUERY' });
          return;
        }

        const re = new RegExp(_.escapeRegExp(data.value), 'i');
        const isMatch = (result) => re.test(result.title);

        dispatch({
          type: 'FINISH_SEARCH',
          results: _.filter(options, isMatch),
        });
      }, 300);
    },
    [options],
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleClickResult = (e, data) => {
    dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title });
  };

  return (
    <div className={cx('service-search-bar', 'block')}>
      <Search
        loading={loading}
        onResultSelect={handleClickResult}
        onSearchChange={handleSearchChange}
        results={results}
        value={value}
        resultRenderer={ResultRender}
      />
    </div>
  );
};

export default Body;
